import React, { useState } from "react";
import { Form, Modal, DatePicker, Select } from "antd";
import { Section } from "pages/dashboard/styles";
import Heading from "components/common/heading";
import useToggle from "components/hooks/useToggle";
import { ReactComponent as LocationIcon } from "assets/svgs/location.svg";
import { ReactComponent as UnicornIcon } from "assets/svgs/unicorn.svg";
import { ReactComponent as SelectDropdownIcon } from "assets/svgs/select-dropdown.svg";
import InputStyled from "components/common/inputStyled";
import ButtonStyled from "components/common/buttonStyled";
import CustomSelect from "components/common/customSelect";
import CustomSelectDropdown from "components/common/customSelectDropdown";
import DateDrawer from "components/common/dateDrawer";
import { DemurageSize, useBookingContext } from "context/booking";
import api from "api";
import SuccessTable, { ISuccessProps } from "./successTable";

const dateFormat = "DD/MM/YYYY";

const { RangePicker } = DatePicker;
const FormItem = Form.Item;
const Option = Select.Option;

const DemurrageCalculator = () => {
  const [isCalculating, setIsCalculating] = useState(false);
  const [isLoadingSizes, setIsLoadingSizes] = useState(false);
  const [, setAvailability] = useState({
    date: "",
    space: 0,
  });
  const [demurageShippingSizes, setDemurageShippingSizes] = useState<
    DemurageSize[]
  >([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successData, setSuccessData] = useState<ISuccessProps[]>([]);
  const [values, setValues] = useState<any>({});

  const [form] = Form.useForm();

  const {
    isLoading: isFetchingShippingCompanies,
    demurageSizes,
    shippingData,
  } = useBookingContext();

  const [isOpen, toggleDateDrawer] = useToggle(false);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      const [start_date, end_date] = values.start_end_date;

      const payload = {
        ...values,
        start_date: start_date.format("YYYY-MM-DD"),
        end_date: end_date.format("YYYY-MM-DD"),
      };

      setIsCalculating(true);

      setIsSuccess(false);
      setSuccessData([]);

      const response = await api.booking.calculateDemurage(payload);

      setValues(values);

      setIsCalculating(false);

      if (response.status === 201) {
        const newSuccessData = response.data.data;

        setIsSuccess(true);
        setSuccessData([newSuccessData]);
      } else {
        Modal.error({
          title: "Error",
          content: response.data?.errors,
        });
      }

      console.log(response);
    } catch (error) {
      console.log(error);
      setIsCalculating(false);
    }
  };

  const handleAvailability = (date: string, space: number) => {
    setAvailability({
      date,
      space,
    });

    toggleDateDrawer();
  };

  const handleSelectShippingLine = async (val: string) => {
    await fetchSizes(val);
  };

  const handleSelectContainerType = (value: string) => {
    const selectedContainer = demurageSizes.find((d) => d.id === value);

    if (selectedContainer) {
      form.setFieldsValue({
        free_days: selectedContainer.free_days,
      });
    }
  };

  const handleCloseSuccessTable = () => {
    setIsSuccess(false);
  };

  const fetchSizes = async (shippingLineId: string) => {
    try {
      setIsLoadingSizes(true);

      const response = await api.booking.getDemurageSizesByShippingCompany(
        shippingLineId
      );

      setIsLoadingSizes(false);

      if (response.status === 200) {
        setDemurageShippingSizes(response.data.data);
      }
    } catch (error) {
      setIsLoadingSizes(false);
      console.log(error);
    }
  };

  return (
    <Section>
      <div className="max-width-wrapper">
        <Heading className="title">Calculate Demurrage</Heading>

        <Form
          name="demurrageCalculator"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          initialValues={{
            demurage_type: "import",
          }}
        >
          <FormItem
            label="Select Shipping Line"
            name="shipping_company"
            rules={[
              {
                message: "Please select shipping line",
                required: true,
              },
            ]}
          >
            <CustomSelect
              loading={isFetchingShippingCompanies}
              prefixIcon={<LocationIcon />}
              suffixIcon={<SelectDropdownIcon />}
              dropdownRender={(menu) => <CustomSelectDropdown menu={menu} />}
              dropdownClassName="custom-dropdown-class"
              disabled={isFetchingShippingCompanies}
              onChange={handleSelectShippingLine}
            >
              {shippingData?.map((shippingLine) => (
                <Option key={shippingLine.id} value={shippingLine.id}>
                  {shippingLine.name}
                </Option>
              ))}
            </CustomSelect>
          </FormItem>

          <FormItem
            label="Start and End Date"
            name="start_end_date"
            rules={[
              {
                message: "please select a start and end date",
                required: true,
              },
            ]}
          >
            <RangePicker format={dateFormat} style={{ width: "100%" }} />
          </FormItem>

          <FormItem
            label="Container Type"
            name="size"
            rules={[
              {
                message: "please select container container type",
                required: true,
              },
            ]}
          >
            <CustomSelect
              loading={isLoadingSizes || isFetchingShippingCompanies}
              prefixIcon={<UnicornIcon />}
              dropdownRender={(menu) => <CustomSelectDropdown menu={menu} />}
              dropdownClassName="custom-dropdown-class"
              onChange={handleSelectContainerType}
            >
              {demurageShippingSizes.map((d) => (
                <Option value={d.id}>{`${d.container_type} ${d.size}`}</Option>
              ))}
            </CustomSelect>
          </FormItem>

          <FormItem
            label="Free Days"
            name="free_days"
            rules={[
              {
                message: "Please enter number of free days",
                required: true,
              },
            ]}
          >
            <InputStyled type="number" placeholder="Free Days" />
          </FormItem>

          <FormItem
            label="Demurage Type"
            name="demurage_type"
            rules={[
              {
                message: "please select a demurage type",
                required: true,
              },
            ]}
          >
            <CustomSelect
              prefixIcon={<UnicornIcon />}
              dropdownRender={(menu) => <CustomSelectDropdown menu={menu} />}
              dropdownClassName="custom-dropdown-class"
            >
              <Option value="import">Import</Option>
              <Option value="export">Export</Option>
            </CustomSelect>
          </FormItem>

          <FormItem
            label="Email"
            name="email"
            rules={[
              {
                message: "please enter your email adress",
                required: true,
              },
            ]}
          >
            <InputStyled type="email" placeholder="Email" />
          </FormItem>

          <FormItem>
            <ButtonStyled
              loading={isCalculating}
              htmlType="submit"
              className="calculate-demurrage-btn"
              type="primary"
            >
              Calculate
            </ButtonStyled>
          </FormItem>
        </Form>
      </div>

      {isSuccess && successData.length && (
        <SuccessTable
          data={successData}
          values={values}
          onClose={handleCloseSuccessTable}
        />
      )}

      {isOpen ? (
        <DateDrawer
          visible={isOpen}
          onClose={toggleDateDrawer}
          saveDateAndSpace={handleAvailability}
        />
      ) : null}

      <div className="content-wrapper">
        <header>
          <strong>Demurrage Calculator Disclaimer</strong>
        </header>

        <p>
          These demurrage and detention charges are not an offer and have no
          legal effect. They are estimates only, that may apply to your shipment
          based on the inputs you provided above. Thus, the results may not
          reflect the amounts actually billed. This estimate is calculated
          according to the method of demurrage and detention billing applied by
          each shipping company. In case of a discrepancy between the results
          presented by this calculator and the actual charges billed in the
          invoice issued to you, the amounts and information specified on the
          invoice shall prevail. Please use this calculator at your own
          discretion. MyKontainer Technologies is not responsible for any
          decision or actions taken in reliance upon or as a result of the
          results provided by this calculator.
        </p>
      </div>
    </Section>
  );
};

export default DemurrageCalculator;
