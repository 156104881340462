import React, { useRef } from "react";
import { Button, Row, Table } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import numberFormatter from "utils/numberFormatter";
import { useReactToPrint } from "react-to-print";
import { ReactComponent as CloseIcon } from "assets/svgs/close-icon.svg";
import PrintedDemurrageTable from "./PrintedDemurrageDocument";

export type ISuccessProps = {
  container_type: string;
  start_date: string;
  end_date: string;
  chargeable_days: number;
  amount: number;
  vat_amount: number;
  total: number;
  currency: string;
};

const SuccessTable = ({
  data,
  onClose,
  values,
}: {
  values: any;
  data?: ISuccessProps[];
  onClose?: () => void;
}) => {
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    // print: async (printIframe: HTMLIFrameElement) => {
    //   console.log({
    //     printIframe:
    //       printIframe?.contentWindow?.document.body.innerHTML.toString(),
    //   });

    // Do whatever you want here, including asynchronous work
    // await generateAndSavePDF(printIframe);
    // },
  });

  const columns: any = [
    {
      title: "S/N",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Container Type",
      dataIndex: "container_type",
      key: "container_type",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
    },
    {
      title: "Free Days",
      dataIndex: "free_days",
      key: "free_days",
    },
    {
      title: "Chargeable Days",
      dataIndex: "chargeable_days",
      key: "chargeable_days",
      align: "center",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (record: number) => <span>{numberFormatter(record)}</span>,
    },
    {
      title: "VAT Amount",
      dataIndex: "vat_amount",
      key: "vat_amount",
      render: (record: number) => <span>{numberFormatter(record)}</span>,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (record: number) => <span>{numberFormatter(record)}</span>,
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
  ];

  const dataSource = data?.map((d, i) => ({
    ...d,
    key: i + 1,
  }));

  return (
    <div className="success-table">
      <Row justify="end" className="close-success-table">
        <button onClick={onClose} className="close-btn">
          <CloseIcon />
        </button>
      </Row>
      <Table
        className="table-responsive"
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />

      <Row justify="end" className="actions-wrapper">
        <div className="actions">
          <Button
            type="primary"
            onClick={handlePrint}
            icon={<PrinterOutlined />}
            className="btn btn-primary"
          >
            Print
          </Button>

          {/* <ReactToPrint
            // onBeforeGetContent={() => handlePrintProcess(record)}
            trigger={() => <Button type="link">Print Receipt</Button>}
            content={() => componentRef.current}
          /> */}
          {/* 
          <a href="mailto:?subject=Demurrage Calculator">
            <Button
              type="primary"
              icon={<MailOutlined />}
              className="btn btn-primary"
            >
              Email
            </Button>
          </a> */}

          <div style={{ display: "none" }}>
            <PrintedDemurrageTable
              ref={componentRef}
              values={values}
              shippingAddress={values.shipping_company}
              data={data}
            />
          </div>
        </div>
      </Row>
    </div>
  );
};

export default SuccessTable;
