import React, { ComponentPropsWithRef, forwardRef } from "react";
import { Table } from "antd";
import numberFormatter from "utils/numberFormatter";
import { ISuccessProps } from "../successTable";
import PrintWrapper from "./styles";

import "./styles.ts";

type ITableProps = {
  data?: ISuccessProps[];
  shippingAddress: string;
  values: any;
  // ref: React.MutableRefObject<null>;
};

type AppProps = ComponentPropsWithRef<"div"> & ITableProps;

const PrintedDemurrageTable = forwardRef(
  (props: AppProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { data } = props;

    // const {
    //   userInfo: { first_name, last_name, email, phone },
    // } = useAuthContext();

    const columns: any = [
      {
        title: "S/N",
        dataIndex: "key",
        key: "key",
      },
      {
        title: "Container Type",
        dataIndex: "container_type",
        key: "container_type",
      },
      {
        title: "Start Date",
        dataIndex: "start_date",
        key: "start_date",
      },
      {
        title: "End Date",
        dataIndex: "end_date",
        key: "end_date",
      },
      {
        title: "Chargeable Days",
        dataIndex: "chargeable_days",
        key: "chargeable_days",
        align: "center",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        render: (record: number) => <span>{numberFormatter(record)}</span>,
      },
      {
        title: "VAT Amount",
        dataIndex: "vat_amount",
        key: "vat_amount",
        render: (record: number) => <span>{numberFormatter(record)}</span>,
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
        render: (record: number) => <span>{numberFormatter(record)}</span>,
      },
      {
        title: "Currency",
        dataIndex: "currency",
        key: "currency",
      },
    ];

    const dataSource = data?.map((d, i) => ({
      ...d,
      key: i + 1,
    }));

    // const name = `${first_name} ${last_name}`;

    return (
      <PrintWrapper ref={ref} {...props}>
        <div className="page-wrapper">
          {/* <div className="address">
            <div className="to-whom">
              <p>
                <strong>Name: </strong>
                {name}
              </p>
              <p>
                <strong>Email: </strong>
                {email}
              </p>

              <p>
                <strong>Phone: </strong>
                {phone}
              </p>
            </div>
          </div> */}

          <Table
            className="table-responsive"
            dataSource={dataSource}
            columns={columns}
            pagination={false}
          />

          <div className="content-wrapper">
            <header>
              <strong>Demurrage Calculator Disclaimer</strong>
            </header>

            <p>
              These demurrage and detention charges are not an offer and have no
              legal effect. They are estimates only, that may apply to your
              shipment based on the inputs you provided above, thus, the results
              may not reflect the amounts actually billed. This estimate is
              calculated according to the method of demurrage and detention
              billing applied by each shipping company. In case of a discrepancy
              between the results presented by this calculator and the actual
              charges billed in the invoice issued to you, the amounts and
              information specified on the invoice shall prevail. Please use
              this calculator at you own discretion. MyKontainer Technologies is
              not responsible for any decision or actions taken in reliance upon
              or as a result of the results provided by this calculator.
            </p>
          </div>
        </div>
      </PrintWrapper>
    );
  }
);

export default PrintedDemurrageTable;
