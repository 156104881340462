import styled from "styled-components";

const PrintWrapper = styled.div`
  padding: 1rem 3rem;

  @media print {
    .page-wrapper {
      margin: 1rem;

      table {
        font-size: 0.75rem;
      }

      .address {
        padding: 1rem 0;
        margin-bottom: 3rem;

        .to-whom {
          margin-bottom: 1rem;
          font-size: 12px;

          p {
            margin-bottom: 10px;
          }
        }

        .shipment {
          font-size: 12px;
          display: flex;
          justify-content: end;

          div {
            p {
              margin-bottom: 10px;
            }
          }
        }
      }

      .content-wrapper {
        margin-top: 6rem;

        header {
          margin-bottom: 1rem;
        }

        p {
          font-size: 12px;
          text-align: justify;
        }
      }
    }
  }
`;

export default PrintWrapper;
