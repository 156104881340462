import { AxiosInstance, AxiosResponse } from "axios";

export type IUserProps = {
  id: number;
  first_name: string;
  last_name: string;
  phone: string;
  address: string;
  email: string;
  role: string;
  is_admin: boolean;
  refresh?: string;
  access?: string;
  shipping_company?: string;
  bay_area?: string;
  user_type: string;
  company_name?: string;
};

export type IUserSignUpProps = Omit<
  IUserProps,
  "refresh" | "access" | "is_admin" | "role"
> & {
  shipping_company: string;
  bay_area: string;
  customer_type: string;
  company_name?: string;
  password: string;
  re_password: string;
};

class Auth {
  client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  login(userDetails: {
    email: string;
    password: string;
  }): Promise<AxiosResponse<{ data: IUserProps }>> {
    return this.client.post("/auth/login/", userDetails);
  }

  createAccount(body: IUserSignUpProps) {
    return this.client.post("/auth/users/", body);
  }

  activateAccount({ token, uid }: { token: string; uid: string }) {
    return this.client.post(`/auth/users/${token}/${uid}`);
  }

  verifyAccount(otp: string) {
    return this.client.post("/auth/otp/verify/", { otp });
  }

  getUserDetails() {
    return this.client.get("/auth/users/me/");
  }

  updateUser(userDetails: IUserProps) {
    return this.client.put("/auth/users/me/", userDetails);
  }

  changePassword(body: { current_password: string; new_password: string }) {
    return this.client.put("/auth/users/set_password/", body);
  }

  forgotPassword(email: string) {
    return this.client.post("/auth/users/reset_password/", { email });
  }

  forgotPasswordConfirm(body: { token: string; new_password: string }) {
    return this.client.post("/auth/users/reset_password/confirm/", body);
  }
}

export default Auth;
